import { Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Subject, combineLatest } from "rxjs";
import { finalize, takeUntil, take } from "rxjs/operators";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { ConfigMilestoneModalComponent } from "@modules/reports/components/custom/milestones/config-modals/config-milestone-modal.component";
import { FilterTypesEnum } from "../../../../../../_enums/filter-types-enum";
import { TranslateService } from "@ngx-translate/core";
import { ReportsService } from "@api/reports/services/reports.service";
import { sanitizeBoolean } from "src/app/lib/sanitizers/boolean.sanitizer";

@Component({
  selector: "app-milestones-report-list",
  templateUrl: "./milestones-report-list.component.html",
  styleUrls: ["./milestones-report-list.component.scss"],
})
export class MilestonesReportListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy, OnChanges
{
  isFiltersLoaded: boolean = true;

  private destroyed$: Subject<void> = new Subject();

  selectedColumns = [];
  selectedMilestones = [];
  queryParams: any;

  edit: boolean = false;
  customMilestoneId: number;
  saveButtonLoading: boolean = false;
  reportName: string = "";
  constructor(
    private reports: ReportsService,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private router: Router
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForMilestoneReports()
    );

    this.isLoading = true;
    this.watchQueryParams();
  }

  ngOnChanges(changes) {
    if (changes.overdue) {
      this.getItems(this.filters.page);
    }
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;
    this.isFiltersLoaded = true;

    const params = this.getParams(this.filters.page);

    if (params.page === undefined) {
      params.page = 1;
    }

    this.request_call = this.reports
      .milestones(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.selectedColumns = Object.values(data.selected.columns);
          this.selectedMilestones = Object.values(data.selected.milestones);

          this.processResponse({ result: data });
          if (data.filters.length) {
            this.isFiltersLoaded = false;
          }
        },
        error: (error) => {
          this.items = [];
          this.isFiltersLoaded = false;
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });

    if (params.id !== "new") {
      this.reports.customMilestoneView(params.id).subscribe((data) => {
        if (data.success) {
          this.reportName = data.result.name;
        }
      });
    }
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        if (params.id !== "new") {
          this.edit = true;
          this.customMilestoneId = params.id;
        }
        const filters = { ...params, ...query };
        this.queryParams = query;
        super.watchQueryParams(filters);
      }
    );
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        replaceUrl: true,
      })
      .then();
  }

  navigateByParams(params) {
    this.filters = params;
    this.navigateByUrl();
  }

  protected getService() {
    return this.reports;
  }

  openMilestoneConfigModal() {
    const dialogRef = this.dialog.open(ConfigMilestoneModalComponent, {
      data: {
        saveButton: true,
        reportName: this.reportName,
      },
    });

    dialogRef.afterClosed().subscribe((params) => {
      if (params) {
        this.filters = { ...this.filters, ...params };
        if (!params.columns) {
          delete this.filters.columns;
        }

        if (!params.milestone_columns) {
          delete this.filters.milestone_columns;
        }
      }

      if (params.save) {
        if (this.filters.id !== "new") {
          this.customMilestoneId = this.filters.id;
          this.edit = true;
        } else {
          this.edit = false;
        }
        this.createOrUpdate();
      } else {
        this.navigateByUrl();
      }
    });
  }

  createOrUpdate() {
    this.saveButtonLoading = true;
    const params = this.getParams(1);
    delete params.id;
    delete params.order;
    delete params.order_direction;
    delete params.page;

    if (this.edit) {
      this.reports
        .updateCustomMilestone(this.customMilestoneId, {
          params: JSON.stringify(params),
          name: params.reportName,
        })
        .pipe(
          take(1),
          finalize(() => {
            this.saveButtonLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            this.alertService.success(
              this.translate.instant("GENERALS.SUCCESSFULLY-UPDATED")
            );

            this.router
              .navigate(["/reports/milestone/", data.id], {
                queryParams: params,
                replaceUrl: true,
              })
              .then();
          },
          error: (error) => {
            this.items = [];
            if (error?.error?.errors) {
              this.alertService.errors(error.error.errors);
            }
          },
        });
    } else {
      this.reports
        .createCustomMilestone({
          params: JSON.stringify(params),
          name: params.reportName,
        })
        .pipe(
          take(1),
          finalize(() => {
            this.saveButtonLoading = false;
          })
        )
        .subscribe({
          next: (data) => {
            this.router
              .navigate(["/reports/milestone/", data.id], {
                queryParams: params,
                replaceUrl: true,
              })
              .then();
            this.alertService.success(this.translate.instant("GENERALS.SAVED"));
          },
          error: (error) => {
            if (error?.error?.errors) {
              this.alertService.errors(error.error.errors);
            }
          },
        });
    }
  }

  clearAllFilters() {
    const columns = this.filters.columns;
    this.clearFilters();
    if (columns !== undefined) {
      this.filters.columns = columns;
    }
    this.navigateByUrl();
  }

  get FilterType() {
    return FilterTypesEnum;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  get endDateColumn() {
    return sanitizeBoolean(this.filters.show_end_dates)
      ? "forecast_end_date"
      : "end_date";
  }
}
