import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseTaskListingFilters, CaseTasksService } from "@api/case-tasks";
import { CaseData, EligibleUserData } from "@api/cases";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { ReplaySubject, Subject, finalize, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  templateUrl: "./task-create.component.html",
  styleUrls: ["./task-create.component.scss"],
})
export class TaskCreateComponent extends ItemsListComponent implements OnInit {
  public taskForm: UntypedFormGroup;
  nameControl = new FormControl("", Validators.required);
  durationControl = new FormControl("", [
    Validators.required,
    Validators.min(1),
  ]);
  startAfterControl = new FormControl(null);
  assigneeControl = new FormControl("", Validators.required);
  startAfterOptions = [];
  users$ = new ReplaySubject<EligibleUserData[]>();
  filters: Partial<CaseTaskListingFilters> = {};

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: {
        text: string;
      };
      case: CaseData & { shouldShowPreview: boolean };
      parentId?: number;
      case_service_id: number;
    },
    private dialogRef: MatDialogRef<TaskCreateComponent>,
    private caseTasksService: CaseTasksService,
    private fb: FormBuilder,
    private caseTaskService: CaseTasksService
  ) {
    super();
  }

  ngOnInit() {
    this.setupForm();
    this.getTasksList();
  }

  submit() {
    this.taskForm.markAllAsTouched();

    if (!this.taskForm.valid) {
      return;
    }
    const formValue = this.taskForm.getRawValue();
    const data = {
      ...formValue,
      expat_case_id: this.data.case.id,
      parent_id: this.data.parentId,
      case_service_id: this.data.case_service_id,
    };

    this.isLoading = true;

    this.caseTasksService.create(data).subscribe((data) => {
      this.dialogRef.close({
        type: "saved",
        task: data.task,
      });
    });
  }

  private setupForm(): void {
    this.taskForm = this.fb.group({
      name: this.nameControl,
      duration: this.durationControl,
      dependent_task_id: this.startAfterControl,
      assignee_id: this.assigneeControl,
      role_type: null,
    });

    this.taskForm.get("assignee_id").disable();
  }

  public handleStartAfter(event) {
    this.taskForm.get("dependent_task_id").setValue(event);

    this.caseTaskService
      .getEligibleUsers(this.data.case.id, event)
      .pipe(map((users) => [...users]))
      .subscribe((users) => this.users$.next(users));
    this.taskForm.get("assignee_id").enable();
  }

  getTasksList() {
    let disable: boolean;
    let nextTask: boolean;

    this.filters.case_service_id = this.data.case_service_id.toString();
    this.filters.expat_case_id = this.data.case.id.toString();
    this.filters.parent_id = null;
    this.filters.per_page = "100";
    this.filters.include = "";

    this.caseTasksService
      .fetchTasks(this.filters)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res) => {
        res.items.forEach((item) => {
          disable =
            this.data.parentId == item.id ||
            (this.data.parentId && item.subtasks.length > 0);
          this.startAfterOptions.push({
            id: item.id,
            name: item.name,
            subtask: false,
            disable: nextTask ?? disable,
          });

          if (item.subtasks) {
            item.subtasks.forEach((subtask) => {
              this.startAfterOptions.push({
                id: subtask.id,
                name: subtask.name,
                subtask: true,
                disable: nextTask ?? false,
              });
            });
          }

          if (this.data.parentId && this.data.parentId == item.id) {
            nextTask = true;
          }
        });
        this.caseTaskService
          .getEligibleUsers(this.data.case.id, res.items[0].id)
          .pipe(map((users) => [...users]))
          .subscribe((users) => this.users$.next(users));
        this.taskForm.get("assignee_id").enable();
      });
  }

  public close(): void {
    this.dialogRef.close({ action: "cancel" });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
