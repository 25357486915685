import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ReportsCaseService } from "@modules/reports/services/reports-case.service";
import { PageData } from "@modules/shared/models/page.model";
import {
  Observable,
  ReplaySubject,
  debounceTime,
  finalize,
  forkJoin,
  map,
  switchMap,
  take,
} from "rxjs";
import { PieChartNameEnum } from "../../enums/pie-chart-names.enum";
import { CaseReportsParams } from "../../models/case-reports-params.model";
import { ChartServerData } from "../../models/chart-server-data.model";
import { CaseService } from "@api/cases";
import { ChartCardComponent } from "@modules/reports/reports-shared/components/chart-card/chart-card.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CountryPipe } from "@modules/shared/_pipes/country.pipe";

@Component({
  selector: "app-estimated-vs-actual-duration",
  templateUrl: "./estimated-vs-actual-duration.component.html",
  styleUrls: ["./estimated-vs-actual-duration.component.scss"],
})
export class EstimatedVsActualDurationComponent
  extends ChartCardComponent
  implements OnInit
{
  boxes = [];
  form: FormGroup;
  readonly defaultChartData = [
    {
      name: PieChartNameEnum.DEFAULT,
      label: "avg. estimated duration",
      color: "#D0DAE4",
      value: 0,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: "avg. actual duration",
      color: "#D0DAE4",
      value: 1,
    },
  ];

  params = {
    page: 1,
    search_text: "",
    country: "",
    status: "done",
  };
  totalServices: number;
  serviceSearchFilterCtrl: FormControl = new FormControl();
  countrySearchFilterCtrl: FormControl = new FormControl();
  filteredCountries$: ReplaySubject<{ id: string; text: string }[]> =
    new ReplaySubject<{ id: string; text: string }[]>(1);
  countries: { id: string; text: string }[] = [];
  filteredServices$: ReplaySubject<CaseService[]> = new ReplaySubject<
    CaseService[]
  >(1);
  services: CaseService[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private reportsCaseService: ReportsCaseService,
    private countryPipe: CountryPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupForm();
    this.getItems();
    this.initEmptyPie();

    this.serviceSearchFilterCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((search: string) => {
        if (this.params.search_text !== search) {
          this.params.search_text = search;
          this.params.page = 1;
          this.getItems();
        }
      });

    this.countrySearchFilterCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        switchMap((search: string) => this.getFilteredCountries(search))
      )
      .subscribe((filteredCountries) =>
        this.filteredCountries$.next(filteredCountries)
      );

    this.form
      .get("country")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((countryCode) => {
        this.form.get("service_id").reset();
        this.params.country =
          typeof countryCode !== "undefined" ? countryCode : "";
        this.filteredServices$.next([]);
        this.initEmptyPie();
        this.getItems();
      });

    this.form
      .get("service_id")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => {
        this.submitForm();
      });
  }

  private setupForm(): void {
    this.form = this.fb.group({
      country: [""],
      service_id: ["", Validators.required],
    });
  }

  protected setValues(chartData: ChartServerData[]): void {
    if (chartData.findIndex((item) => item.value > 0) === -1) {
      this.initEmptyPie();
      return;
    }

    this.pieChartCustomColors = chartData
      .filter(
        (data) =>
          data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
          data.name === PieChartNameEnum.ESTIMATED_VS_ACTUAL
      )
      .map((item) => {
        return {
          ...item,
          value: item.color,
        };
      });

    this.chartItems = chartData
      .filter(
        (data) =>
          data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
          data.name === PieChartNameEnum.ESTIMATED_VS_ACTUAL
      )
      .map((item) => {
        return {
          ...item,
          value: item.value,
        };
      });

    this.boxes = chartData.filter(
      (data) =>
        data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
        data.name === PieChartNameEnum.AVERAGE_PROCESS
    );

    // Swapping item places so Actual is second
    [this.boxes[0], this.boxes[1]] = [this.boxes[1], this.boxes[0]];
  }

  private submitForm(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const params: CaseReportsParams = this.form.getRawValue();

    this.isLoading = true;
    this.reportsCaseService
      .getEstimatedActualProcessDuration(params)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (data) => {
          this.setValues(data);
        },
        error: () => {
          this.initEmptyPie();
        },
      });
  }

  getItems(triggeredByScroll: boolean = false): void {
    this.isLoading = true;

    this.reportsCaseService
      .getServices(this.params, this.reload)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.reload = true;
        })
      )
      .subscribe({
        next: (data: PageData<CaseService>) => {
          const offers = data.items;

          if (triggeredByScroll) {
            this.services = [...this.services, ...offers];
          } else {
            this.services = offers;
          }

          this.filteredServices$.next(this.services);
          this.countries = data.filters.find(
            (filter) => filter.id === "host_country"
          ).data;
          this.filteredCountries$.next(this.countries);
          this.totalServices = data.total;
        },
      });
  }

  getFilteredCountries(
    search: string
  ): Observable<{ id: string; text: string }[]> {
    const countryObservables = this.countries.map((country) =>
      this.countryPipe
        .transform(country.text)
        .pipe(map((transformedText) => ({ ...country, transformedText })))
    );

    return forkJoin(countryObservables).pipe(
      map((transformedCountries) =>
        transformedCountries.filter((country) =>
          country.transformedText.toLowerCase().includes(search.toLowerCase())
        )
      )
    );
  }

  getNextBatch(): void {
    this.params.page += 1;
    this.getItems(true);
  }

  trackByCountry(index: number, country: { id: string; text: string }) {
    return country.id;
  }

  get PieChartName() {
    return PieChartNameEnum;
  }
}
