import { Component, OnInit } from "@angular/core";
import { BreadcrumbService } from "@services/breadcrumb.service";

@Component({
  selector: "app-expenses-report-list",
  templateUrl: "./expenses-report-list.component.html",
  styleUrls: ["./expenses-report-list.component.scss"],
})
export class ExpensesReportListComponent implements OnInit {
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForExpenseReports()
    );
  }
}
