import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CostCategoryData } from "@api/expenses/models/cost-category.model";
import { ExpensePayerTypeData } from "@api/expenses/models/expense-payer.model";
import { ExpenseData } from "@api/expenses/models/expenses.model";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { fileValidator } from "@modules/shared/_validators/file-extension.validator";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@services/alert.service";
import moment from "moment";
import { finalize, Observable, shareReplay } from "rxjs";

export interface CreateExpenseComponentInputData {
  taskId?: number;
  expense?: ExpenseData;
  entityType: string;
  entityId: number;
}

@Component({
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateExpenseComponent implements OnInit {
  fileRemoved = true;
  expenseForm: UntypedFormGroup;
  isLoading = true;
  public fileUploadControl = new FileUploadControl();

  entityType: string;
  entityId: string;
  costCategories$: Observable<CostCategoryData[]>;
  coveredBy$: Observable<ExpensePayerTypeData[]>;

  constructor(
    private expensesService: ExpensesService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: CreateExpenseComponentInputData,
    protected dialogRef: MatDialogRef<CreateExpenseComponent>,
    public translate: TranslateService
  ) {
    this.expenseForm = new UntypedFormGroup({
      file: new UntypedFormControl([], [Validators.required, fileValidator()]),
      incurred_at: new UntypedFormControl("", [Validators.required]),
      currency_code: new UntypedFormControl("", Validators.required),
      amount: new UntypedFormControl("", Validators.required),
      title: new UntypedFormControl("", Validators.required),
      cost_category_id: new UntypedFormControl("", Validators.required),
      covered_by: new UntypedFormControl("", Validators.required),
    });

    this.expenseForm.get("file").valueChanges.subscribe(() => {
      if (this.expenseForm.get("file").hasError("fileExtension")) {
        this.alertService.error(
          this.translate.instant("VALIDATION.FILE_EXTENSION_TYPE")
        );
      }
    });
  }

  get isEdit(): boolean {
    return this.data?.expense?.id > 0;
  }

  ngOnInit() {
    this.isLoading = false;
    this.expenseForm.markAsUntouched();
    if (this.data.expense) {
      const expense = this.data.expense;
      this.expenseForm.patchValue({
        incurred_at: expense.incurred_at,
        covered_by: expense.covered_by_id,
        currency_code: expense.currency_code,
        amount: expense.amount,
        title: expense.title,
        cost_category_id: expense.cost_category_id,
      });
      this.fileRemoved = false;
    }

    this.costCategories$ = this.expensesService
      .getCostCategory()
      .pipe(shareReplay(1));
    this.coveredBy$ = this.expensesService
      .getExpensePayers()
      .pipe(shareReplay(1));

    if (this.isEdit) {
      this.expenseForm.get("file").clearValidators();
      this.expenseForm.get("file").updateValueAndValidity();
    }
  }

  submit() {
    const data = this.expenseForm.getRawValue();

    if (this.isEdit && !this.fileRemoved) {
      data.file = [this.data.expense.file];
    }

    this.expenseForm.markAllAsTouched();

    if (this.expenseForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.expenseForm.disable({ emitEvent: false });
    if (this.data.taskId) {
      data.task_id = this.data.taskId;
    }
    this.getBackendAction(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.expenseForm.enable({ emitEvent: false });
        })
      )
      .subscribe((documentData) => {
        this.dialogRef.close({
          type: "saved",
          documentData,
        });
      });
  }

  private getBackendAction(data) {
    if (this.isEdit) {
      return this.expensesService.editExpense(
        this.data.entityType,
        this.data.entityId,
        this.data.expense.id,
        data
      );
    } else {
      return this.expensesService.create(
        this.data.entityType,
        this.data.entityId,
        data
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  setDate(date: any): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.expenseForm.get("incurred_at").setValue(moment(date).toDate());
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  removeFile() {
    this.fileRemoved = true;
    this.expenseForm.get("file").clearValidators();
    this.expenseForm
      .get("file")
      .setValidators([Validators.required, fileValidator()]);
    this.expenseForm.get("file").updateValueAndValidity();
  }

  get shouldUploadFile(): boolean {
    return !this.isEdit || this.fileRemoved;
  }
}
