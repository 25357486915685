<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div *ngIf="!isOnDashboard" class="dashboard-body-top m-0">
  <!-- left -->
  <div class="float-left big-title">
    {{ "LEFT-NAVIGATION.DOCUMENTS" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="button-bar">
    <ng-content select="[slot='top-buttons']"> </ng-content>
    <button class="btn-outline-primary" (click)="export()">
      <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
      {{ "GENERALS.EXPORT" | translate }}
    </button>
    <button class="btn-primary" *ngIf="showUploadButton" (click)="openDialog()">
      <mat-icon svgIcon="upload" class="btn-icon"></mat-icon>
      {{ "DOCUMENTS.UPLOAD" | translate }}
    </button>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div *ngIf="!isOnDashboard" class="dashboard-body-action">
  <!-- left -->
  <div
    class="float-left advanced-search-wrapper docs-filters w-full p-0 mt-4"
    *ngIf="isFiltersLoaded"
  >
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LEFT-NAVIGATION.DOCUMENTS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="m-fix">
  <app-loader-spinner-general
    *ngIf="!isFiltersLoaded"
    [loadingType]="LoadingType.FILTER"
  ></app-loader-spinner-general>
</div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      mat-table
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
      [dataSource]="
        items
          | paginate
            : {
                itemsPerPage: itemsPage,
                currentPage: p,
                totalItems: total
              }
      "
    >
      <ng-container matColumnDef="select" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef class="w-1 border-r-white">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [disableRipple]="true"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let item">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (keyup)="$event.stopPropagation()"
            (change)="$event ? changeSelection(item) : null"
            [checked]="selection.isSelected(item)"
            [disableRipple]="true"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id" [sticky]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="id"
          [disabled]="isOnDashboard"
          class="text-right border-r border-r-gray-light w-1"
        >
          {{ "GENERALS.ID" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          class="text-right border-r border-r-gray-light"
        >
          <a
            [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
            class="text-secondary font-semibold text-right"
          >
            #{{ item.id }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="name"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.NAME" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          <span class="flex">
            <img
              class="doc-type-icon"
              [ngSrc]="
                'assets/images/doc-type-' +
                item.file_type.toLowerCase() +
                '.svg'
              "
              alt=""
              width="20"
              height="20"
            />
            <div class="ml-1.5">
              {{ item.name }}
            </div>
          </span>
          <div class="tags">
            <ng-container *ngFor="let tag of item.tags">
              <span class="ml-1.5"> #{{ tag.name }} </span>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="client">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="client_name"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.CLIENT-NAME" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.client_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expat">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="expat_name"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.EXPAT-NAME" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.expat_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="document_type"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.TYPE" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.document_type?.text }}
        </td>
      </ng-container>
      <ng-container matColumnDef="author">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="created_by"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.UPLOADED_BY" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.created_by?.text }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expiration">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="expiration_date"
          [disabled]="isOnDashboard"
          class="text-right"
        >
          {{ "DOCUMENTS.EXPIRES_AT" | translate }}
        </th>
        <td
          mat-cell
          class="text-right"
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.expiration_date | date : "dd/MM/yyyy" | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="upload_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="created_at"
          [disabled]="isOnDashboard"
          class="text-right"
        >
          {{ "DOCUMENTS.UPLOADED-AT" | translate }}
        </th>
        <td
          mat-cell
          class="text-right"
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.created_at | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="document_number">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="number"
          [disabled]="isOnDashboard"
          class="text-right"
        >
          {{ "DOCUMENTS.NUMBER" | translate }}
        </th>
        <td
          mat-cell
          class="text-right"
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.number | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="country"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.COUNTRY" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item.country | country | async | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="service_category">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="service_category"
          [disabled]="isOnDashboard"
        >
          {{ "LABEL.SERVICE-CATEGORY" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          {{ item?.service_category | titlecase | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="status"
          [disabled]="isOnDashboard"
        >
          {{ "DOCUMENTS.STATUS" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          <div
            class="status"
            [appStatusStyle]="item.status_label | lowercase"
            [matMenuTriggerFor]="info"
            #infoTrigger="matMenuTrigger"
            (mouseover)="infoTrigger.openMenu()"
            (focus)="infoTrigger.openMenu()"
          >
            {{ item.status_label }}
          </div>
          <mat-menu
            class="status-title {{
              item.status_id === DocumentType.IN_RENEWAL ||
              item.status_id === DocumentType.REMINDER_SET
                ? ''
                : 'hidden'
            }}"
            #info="matMenu"
            [overlapTrigger]="false"
          >
            <span (mouseleave)="infoTrigger.closeMenu()">
              <span
                class="px-2"
                *ngIf="item.status_id === DocumentType.REMINDER_SET"
              >
                {{ item.reminder_date | date : "dd/MM/yyyy hh:mm aaa" }}
              </span>
              <span
                class="flex items-center px-2 font-semibold whitespace-nowrap"
                *ngIf="item.status_id === DocumentType.IN_RENEWAL"
              >
                {{ "LEFT-NAVIGATION.CASE" | translate }}:
                <a
                  routerLink="/cases/{{ item.entity_id }}"
                  routerLinkActive="active"
                  target="_blank"
                  class="text-primary underline cursor-pointer"
                  mat-menu-item
                >
                  #{{ item.entity_id }}
                </a>
              </span>
            </span>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" [stickyEnd]="true">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-selection
            [columns]="columns"
            [page]="StorePageTypes.DOCUMENTS_LIST"
          ></app-column-selection>
        </th>
        <td
          mat-cell
          class="text-right"
          *matCellDef="let item"
          [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="documentMenu"
            class="btn-outline-none-primary items-end"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #documentMenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              class="uppercase"
              (click)="downloadDocument(item.id)"
            >
              {{ "GENERALS.DOWNLOAD-DOCUMENT" | translate }}
            </button>
            <button
              mat-menu-item
              class="uppercase"
              (click)="linkDocument(item)"
            >
              {{ "GENERALS.LINK" | translate }}
            </button>
            <button
              mat-menu-item
              class="uppercase"
              (click)="shareDocument(item)"
            >
              {{ "GENERALS.SHARE-DOCUMENT" | translate }}
            </button>
            <a
              mat-menu-item
              class="no-underline uppercase"
              [routerLink]="isOnDashboard ? ['/docs', item.id] : [item.id]"
              [queryParams]="{ name: item.name }"
            >
              {{ "GENERALS.REMINDERS" | translate }}
            </a>
            <ng-container *ngFor="let status of actions[item.status_id]">
              <button
                mat-menu-item
                class="uppercase"
                *ngIf="hasPermission(item, status.id)"
                (click)="changeStatus(status, item)"
              >
                {{ status.text | translate }}
              </button>
            </ng-container>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="loading">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <app-loader-spinner-general
            [loadingType]="LoadingType.TABLE"
          ></app-loader-spinner-general>
        </td>
      </ng-container>
      <ng-container matColumnDef="no-data">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <div
            class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
          >
            {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getColumns(); sticky: true"></tr>
      <tr
        mat-row
        [hidden]="isLoading"
        *matRowDef="let row; columns: getColumns()"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['loading']"
        [hidden]="!isLoading"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['no-data']"
        [hidden]="items?.length || isLoading"
      ></tr>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="!isOnDashboard ? navigateByUrl($event) : getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
